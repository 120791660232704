:root {
  --main-text-color: #1a202c;
  --primary-text-color: #122c6c;
  --secondary-text-color: #6b809c;
  //
  --primary-bg-color: rgba(237, 242, 247, 1);
  //
  --highlight-color: rgb(246, 142, 19);

  //
  --orange-color: #ff8200;
  --gray-color: #6f8295;
  --white-color: #ffffff;
  --blue-color: #002e5d;
}
